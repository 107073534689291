import { Box, Container, Grid, Hidden, makeStyles } from '@material-ui/core';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import React, { useCallback, useMemo, useState } from 'react';
import { PrimaryButton, TextButton } from '../Buttons';
import IconButton from '../IconButton/IconButton';
import Icon from '../Icon';
import Link from '../Link';
import MenuDropdown from '../MenuDropdown';
import StudioDrawer from '../StudioDrawer';
import createSigoutProps from '../../lib/createSignoutProps';

type MenuItemLinkType = { label: string; path: string };
type MenuItemDivider = { divider: true };
type ParentMenuItemLinkType = MenuItemLinkType & {
  children: Array<MenuItemLinkType | MenuItemDivider>;
};

const MainNavigationMenuItems: Array<
  MenuItemLinkType | ParentMenuItemLinkType
> = [
  {
    label: 'Product',
    path: '/features',
    children: [
      {
        label: 'Features',
        path: '/features',
      },
      {
        label: '   Studio',
        path: '/features/studio',
      },
      {
        label: '   API',
        path: '/features/api',
      },
      {
        label: '   Avatars',
        path: '/features/avatars',
      },
      {
        divider: true,
      },
      {
        label: 'Security',
        path: '/enterprise',
      },
      {
        label: 'AI & Ethics',
        path: '/ethics',
      },
    ],
  },
  {
    label: 'Use Cases',
    path: '#',
    children: [
      {
        label: 'Corporate Training',
        path: '/corporatetraining',
      },
      {
        label: 'Advertising',
        path: '/advertising',
      },
      {
        label: 'Products & Experiences',
        path: '/aivoiceapi',
      },
      {
        label: 'Video Production',
        path: '/video',
      },
    ],
  },
  {
    label: 'Solutions',
    path: '/solutions',
  },
  {
    label: 'Teams',
    path: '/teams',
  },
  {
    label: 'Pricing',
    path: '/pricing',
  },
];

const { WEB_URI } = getConfig().publicRuntimeConfig;

const useStyles = makeStyles(theme => ({
  logoContainer: {
    [theme.breakpoints.up('sm')]: {
      // NOTE: prevents layout jumping when image first renders, also centers the top level nav
      minWidth: 196,
    },
  },
  logo: {
    display: 'block', // ensure not effected by line height
    height: 24,
    width: 'auto',
    cursor: 'pointer',
  },
  topLevelNav: {
    color: theme.palette.text.primary,
    '& a': {
      color: 'inherit',
    },
    '& > a, & > button': {
      margin: theme.spacing(0, 3),
    },
    '& a.active': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.mixins.toolbar.minHeight as number,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(8),
    },
  },
  mobileMenuIcon: {
    color: theme.palette.text.primary,
    marginRight: theme.spacing(-1),
  },
  drawerHeader: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    position: 'fixed',
    width: `calc(100vw - 50px)`,
    maxWidth: theme.mixins.drawer.width,
    top: 0,
    zIndex: 1,
  },
  menuItemMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  menuLink: {
    width: '100%',
    padding: theme.spacing(0, 2),
    textDecoration: 'none !important',
  },
  externalAnchor: {
    color: theme.palette.common.white,
    '&:focus': {
      color: theme.palette.primary.main,
      textDecoration: 'none !important',
    },
  },
}));

/**
 * There are currently two variants of the MainNavigation component, so this component factors
 * out some of the shared component tree.
 * @param children Pass <Grid item> components as the children that will be rendered within the
 *        <Grid container>
 */
const AppBarWithGridContainer: React.FC<AppBarProps> = ({
  children,
  ...props
}) => {
  return (
    <AppBar {...props}>
      <Toolbar disableGutters>
        <Container>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="space-between"
          >
            {children}
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

const MainNavigation = () => {
  const classes = useStyles();
  const router = useRouter();
  const pathname = router ? router.asPath : '';
  return (
    <AppBarWithGridContainer
      color="transparent"
      position="relative"
      elevation={0}
    >
      <Grid item className={classes.logoContainer}>
        <Hidden xsDown>
          <a href={WEB_URI}>
            <img
              className={classes.logo}
              src="/static/images/logo/type.svg"
              alt="Logo"
            />
          </a>
        </Hidden>
        <Hidden smUp>
          <a href={WEB_URI}>
            <img
              className={classes.logo}
              src="/static/images/logo/mark.svg"
              alt="Logo"
            />
          </a>
        </Hidden>
      </Grid>
      <Hidden smDown>
        {/* Desktop Menu */}
        <MainNavigationMenuDesktop classes={classes} pathname={pathname} />
      </Hidden>
      <Hidden mdUp>
        {/* Mobile Menu */}
        <MainNavigationMenuMobile classes={classes} />
      </Hidden>
    </AppBarWithGridContainer>
  );
};

const MainNavigationMenuDesktop = ({
  classes,
  pathname,
}: {
  classes: ReturnType<typeof useStyles>;
  pathname: string;
}) => (
  <>
    <Grid item>
      <nav className={classes.topLevelNav} role="navigation">
        {MainNavigationMenuItems.map(menuItem => {
          // Dropdown menu
          if ('children' in menuItem) {
            // Parent menu
            const activePaths = menuItem.children
              .map(childMenuItem =>
                'divider' in childMenuItem ? undefined : childMenuItem.path
              )
              .concat([menuItem.path]);
            const menuLinkActive = activePaths.indexOf(pathname) > -1;
            return (
              <MenuDropdown
                key={menuItem.path}
                menuId={`main-nav-dropdown-${menuItem.path}`}
                menuLinkActive={menuLinkActive}
                buttonText={menuItem.label}
                listItems={menuItem.children.map((childMenuItem, idx) => {
                  if ('divider' in childMenuItem) {
                    return {
                      key: `divider-${idx}`,
                      divider: true,
                      children: null,
                    };
                  }
                  return {
                    key: childMenuItem.path,
                    disableGutters: true,
                    children: (
                      <Link href={childMenuItem.path} passHref>
                        <a
                          href={childMenuItem.path}
                          className={classes.menuLink}
                        >
                          {childMenuItem.label}
                        </a>
                      </Link>
                    ),
                  };
                })}
              />
            );
          }
          // Link
          return (
            <Link key={menuItem.path} href={menuItem.path} passHref>
              <TextButton color="primary" size="small">
                {menuItem.label}
              </TextButton>
            </Link>
          );
        })}
      </nav>
    </Grid>
    <Grid item>
      <Box display="flex" alignItems="center">
        <Box>
          <a
            className={classes.externalAnchor}
            href={`${WEB_URI}/auth/sign_in_redirect?redirect_to=${encodeURIComponent(
              '/dashboard'
            )}`}
          >
            <TextButton
              tabIndex={-1}
              color="primary"
              size="small"
            >{`Sign In`}</TextButton>
          </a>
        </Box>
        <Box marginLeft={4}>
          <Link href={`${WEB_URI}/auth/request_access`} passHref>
            <PrimaryButton size="small" style={{ width: 112, height: 40 }}>
              {`try for free`}
            </PrimaryButton>
          </Link>
        </Box>
      </Box>
    </Grid>
  </>
);

const MainNavigationMenuMobile = ({
  classes,
}: {
  classes: ReturnType<typeof useStyles>;
}) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const flattenMenuItems = MainNavigationMenuItems.reduce<
    Array<MenuItemLinkType>
  >((acc, cur) => {
    if ('children' in cur) {
      return acc.concat(
        cur.children.filter(child => {
          // Not pretty, but filtering the "divider" from mobile menu
          return 'path' in child;
        }) as Array<MenuItemLinkType>
      );
    }
    return acc.concat(cur);
  }, []);
  return (
    <Grid item>
      <IconButton
        onClick={() => setMobileNavOpen(true)}
        aria-label="open mobile navigation menu"
        className={classes.mobileMenuIcon}
      >
        <Icon type="menu" />
      </IconButton>
      <StudioDrawer
        anchor="right"
        open={mobileNavOpen}
        onClose={() => setMobileNavOpen(false)}
      >
        <div className={classes.drawerHeader}>
          <IconButton
            aria-label="close toolbar action"
            onClick={() => setMobileNavOpen(false)}
          >
            <Icon type="chevronLeft" />
          </IconButton>
          <Box marginLeft="auto">
            <Box marginRight={2} display="inline">
              <a
                className={classes.externalAnchor}
                href={`${WEB_URI}/auth/sign_in_redirect?redirect_to=${encodeURIComponent(
                  '/dashboard'
                )}`}
              >
                <PrimaryButton
                  color="secondary"
                  tabIndex={-1}
                  size="small"
                >{`Sign In`}</PrimaryButton>
              </a>
            </Box>
            <Link href={`${WEB_URI}/auth/request_access`} passHref>
              <PrimaryButton size="small">{`try for free`}</PrimaryButton>
            </Link>
          </Box>
        </div>
        <nav className={classes.topLevelNav} role="navigation">
          <TextButton
            className={classes.menuItemMobile}
            color="primary"
            size="small"
            href={WEB_URI}
          >
            {`Home`}
          </TextButton>
          {flattenMenuItems.map(menuItem => {
            // Link
            return (
              <Link key={menuItem.path} href={menuItem.path} passHref>
                <TextButton
                  className={classes.menuItemMobile}
                  color="primary"
                  size="small"
                >
                  {menuItem.label}
                </TextButton>
              </Link>
            );
          })}
        </nav>
      </StudioDrawer>
    </Grid>
  );
};

interface MainNavigationForAuthPagesProps {
  isEmbeded?: boolean;
  exitTo?: string | null;
}

export const MainNavigationForAuthPages = ({
  isEmbeded = false,
  exitTo,
}: MainNavigationForAuthPagesProps) => {
  const classes = useStyles();

  const handleClickHomeLogo = useCallback(() => {
    const { protocol, host } = window.location;
    if (window?.top?.location?.href) {
      window.top.location.href = `${protocol}//${host}`;
    }
  }, []);

  const logoutProps = useMemo(
    () =>
      exitTo
        ? {
            href: exitTo,
          }
        : createSigoutProps(WEB_URI),
    [exitTo]
  );

  return (
    <>
      <AppBarWithGridContainer color="default" position="fixed">
        {/* Logo */}
        <Grid item>
          {isEmbeded ? (
            <>
              <Hidden xsDown>
                <img
                  className={classes.logo}
                  src="/static/images/logo/type.svg"
                  alt="Logo"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  aria-label="home"
                  onClick={handleClickHomeLogo}
                />
              </Hidden>
              <Hidden smUp>
                <img
                  className={classes.logo}
                  src="/static/images/logo/mark.svg"
                  alt="Logo"
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                  role="button"
                  aria-label="home"
                  onClick={handleClickHomeLogo}
                />
              </Hidden>
            </>
          ) : (
            <>
              <Hidden xsDown>
                <a href={WEB_URI}>
                  <img
                    className={classes.logo}
                    src="/static/images/logo/type.svg"
                    alt="Logo"
                  />
                </a>
              </Hidden>
              <Hidden smUp>
                <a href={WEB_URI}>
                  <img
                    className={classes.logo}
                    src="/static/images/logo/mark.svg"
                    alt="Logo"
                  />
                </a>
              </Hidden>
            </>
          )}
        </Grid>
        {/* Exit icon */}
        {exitTo !== null && (
          <Grid item>
            {isEmbeded ? (
              <div onClick={handleClickHomeLogo} role="button">
                <Icon type="ex" cursor="pointer" />
              </div>
            ) : (
              <a {...logoutProps}>
                <Icon type="ex" cursor="pointer" />
              </a>
            )}
          </Grid>
        )}
      </AppBarWithGridContainer>
      {/* Provides page layout spacing for the fixed position AppBar */}
      <Toolbar />
    </>
  );
};

export default MainNavigation;
