import { Divider, makeStyles, Menu, MenuItem, Theme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import { TextButton } from '../Buttons';
import Icon from '../Icon';

export interface MenuDropdownProps {
  menuId: string;
  menuLinkActive?: boolean;
  buttonText: string;
  showArrow?: boolean;
  listItems: Array<{
    key: string;
    children: ReactNode;
    divider?: boolean;
    disableGutters?: boolean;
  }>;
}

const useStyles = makeStyles<
  Theme,
  { open: boolean } & Pick<MenuDropdownProps, 'menuLinkActive'>,
  'root' | 'menuPaper' | 'divider' | 'button' | 'arrow' | 'listItem'
>(theme => ({
  root: {
    display: 'flex',
  },
  button: {
    color: ({ open, menuLinkActive }) =>
      open || menuLinkActive
        ? theme.palette.primary.main
        : theme.palette.common.white,
    '& > span': {
      color: 'inherit',
    },
  },
  arrow: {
    // width: '0.75em',
    marginLeft: theme.spacing(0.5),
    transform: ({ open }) => `rotate(${open ? '0' : '180'}deg)`,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    '& > path': {
      strokeWidth: '1',
      stroke: 'currentColor',
    },
  },
  menuPaper: {
    minWidth: 120,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  listItem: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    whiteSpace: 'pre',
    fontSize: theme.typography.pxToRem(12),
  },
}));

/**
 *
 * @param {string} buttonText
 * @param {boolean} showArrow Defaults to `false`. Determines if the arrow should render in the nav-item
 * @param {Pick<MenuDropdownProps, 'listItems'>} listItems Array of object with keys of `key` and
 *   `children`. `children` is of type `ReactNode` so it's fairly flexible with what list-item can render
 */
export default function MenuDropdown({
  buttonText,
  showArrow = true,
  listItems,
  menuId,
  menuLinkActive,
}: MenuDropdownProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles({ open, menuLinkActive });

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TextButton
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="primary"
        className={classes.button}
        size="small"
      >
        {buttonText}
        {showArrow && <Icon type="chevronUp" className={classes.arrow} />}
      </TextButton>
      <Menu
        id={menuId}
        classes={{
          paper: classes.menuPaper,
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        disablePortal
      >
        {listItems.map(({ divider, disableGutters = false, ...props }) => {
          if (divider) {
            return <Divider className={classes.divider} key={props.key} />;
          }
          return (
            <MenuItem
              onClick={handleClose}
              className={classes.listItem}
              disableGutters={disableGutters}
              {...props}
            />
          );
        })}
      </Menu>
    </>
  );
}
