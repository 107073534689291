import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';
import React, { Children } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.secondary,
    '&:hover,&.active,&:focus': {
      color: theme.palette.common.white,
      textDecoration: 'none !important',
    },
  },
}));

type ActiveLinkProps = {
  activeClassName?: string;
  includeNavlinkStyling?: boolean;
} & LinkProps;

const ActiveLink: React.FC<ActiveLinkProps> = ({
  children,
  activeClassName = 'active',
  includeNavlinkStyling,
  href,
  as,
  ...props
}) => {
  const classes = useStyles();
  // Not sure why but router can be undefined (SSR only?)
  const router = useRouter();
  const pathname = router ? router.asPath : '';
  const child = Children.only(children);
  if (!child) throw new Error(`ActiveLink requires child anchor tag`);

  const { className } = (child as React.ReactElement<any>).props;
  const childClassName = className || '';

  const comparePathnameTo = typeof as === 'string' ? as : href;

  const isActive = pathname === comparePathnameTo;

  const classNameWithActive = isActive
    ? `${childClassName} ${activeClassName}`.trim()
    : childClassName;

  return (
    <Link href={href} as={as} {...props}>
      {React.cloneElement(child as React.ReactElement<any>, {
        className: `${classNameWithActive} ${
          includeNavlinkStyling ? classes.link : ''
        }`,
        tabIndex: isActive ? -1 : undefined,
        disabled: isActive,
      })}
    </Link>
  );
};

export default ActiveLink;
